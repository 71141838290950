import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import ImageCaptionSection from "../../components/imageCaptionSection";
import HelpMethodsSection from "../../components/helpMethodsSection";
import SuccessStoriesPreviewCard from "./successStoriesPreviewCard";
import Pagination from "../../components/pagination";

function SuccessStoriesIndex(props) {
  const {data, pageContext: {numPages, currentPage}} = props;

  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  const successStoriesPosts = data.allMarkdownRemark.edges.map(successStoriesPost => ({
    ...successStoriesPost.node.fields,
    ...successStoriesPost.node.frontmatter,
  }));

  return (
    <Layout activeLink="/success-stories" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>

      <div className="flex flex-row flex-wrap justify-evenly mt-20">
        {successStoriesPosts.map((successStoriesPost, index) => <SuccessStoriesPreviewCard key={index} successStories={successStoriesPost}/>)}
      </div>
      <Pagination totalPages={numPages} currentPage={currentPage} baseURL="/success-stories/"/>

      <HelpMethodsSection/>
    </Layout>
  );
}

export default SuccessStoriesIndex;

export const successStoriesIndexQuery = graphql`
  query SuccessStoriesIndexQuery($skip: Int!, $limit: Int!) {
    markdownRemark(frontmatter: {templateKey: {eq: "success-stories"}}) {
      frontmatter {
        imageCaptionSection {
          subTitle
          title
          imageCaptionBackground
        }
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/pages/success-stories/"}}
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            coverPicture
          }
        }
      }
    }
  }
`;

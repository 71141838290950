import React from "react";

function Pagination({totalPages, currentPage, baseURL}) {
  let visiblePages = [currentPage];
  for (let i = currentPage - 1; i > (currentPage - 3); i--) {
    i > 0 && visiblePages.splice(0, 0, i);
  }
  for (let i = currentPage + 1; i < (currentPage + 3); i++) {
    i <= totalPages && visiblePages.push(i);
  }

  if (visiblePages.length <= 1) {
    return null;
  }

  const linkBaseClasses = "md:text-xl bg-blue-900 hover:bg-blue-600 text-white px-2 sm:rounded-full";
  return (
    <div className="xl:w-2/3 md:w-4/5 mx-auto py-5 px-5 flex flex-row justify-evenly items-center">
      {currentPage > 1 && <a href={baseURL} className={linkBaseClasses}>
        <span className="hidden sm:inline text-lg">{"<< First"}</span>
        <span className="sm:hidden">{"<<"}</span>
      </a>}
      {currentPage > 1 && <a href={(currentPage - 1) === 1 ? baseURL : `${baseURL}${currentPage - 1}`}
                             className={linkBaseClasses}>
        <span className="hidden sm:inline text-lg">{"< Previous"}</span>
        <span className="sm:hidden">{"<"}</span>
      </a>}
      {visiblePages[0] > 1 && <p className="hidden sm:inline">...</p>}
      {visiblePages.map(pageNum => (
        <a key={pageNum}
           href={pageNum === currentPage ? null : `${baseURL}${pageNum > 1 ? pageNum : ""}`}
           className={`${linkBaseClasses} ${pageNum === currentPage ? "font-bold bg-blue-600" : ""}`}>
          {pageNum}
        </a>
      ))}
      {visiblePages[visiblePages.length - 1] < totalPages && <p className="hidden sm:inline">...</p>}
      {currentPage < totalPages && <a href={`${baseURL}${currentPage + 1}`} className={linkBaseClasses}>
        <span className="hidden sm:inline text-lg">{"Next >"}</span>
        <span className="sm:hidden">{">"}</span>
      </a>}
      {currentPage < totalPages && <a href={`${baseURL}${totalPages}`} className={linkBaseClasses}>
        <span className="hidden sm:inline text-lg">{"Last >>"}</span>
        <span className="sm:hidden">{">>"}</span>
      </a>}
    </div>
  )
}

export default Pagination;
